<template>
  <div id="table-management" class="site-container">
    <div class="general-datatable-wp">
      <v-row no-gutters>
        <v-col cols="12" class="general-datatable-content">
          <v-data-table
            :headers="headers"
            :items="dataForm.records"
            :search="search"
            no-data-text="No Data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            @page-count="pageCount = $event"
            :mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-row no-gutters id="table-search">
                <v-col cols="12" md="4" class="alarm-info-col">
                  <div class="detail-desc">
                    <div class="row-info-space">
                      <p v-if="dataForm?.site" class="project-address">{{ dataForm.site.global }} / {{ dataForm.site.regional }} / {{ dataForm.site.area }} / {{ dataForm.site.project }}</p>
                    </div>
                    <div class="row-info-space">
                      <p>SITE ID</p>
                      <p>{{ dataForm?.site ? dataForm.site.id : '' }}</p>
                    </div>
                    <div class="row-info-space">
                      <p>SITE NAME</p>
                      <p>{{ dataForm?.site ? dataForm.site.site_name : '' }}</p>
                    </div>
                    <div class="row-info-space">
                      <p>COMMISSIONED DATE</p>
                      <p>{{ dataForm?.site?.commissioned_date ? MOMENT(dataForm.site.commissioned_date, "DD/MM/YYYY") : '' }}</p>
                    </div>
                  </div>
                </v-col>  
                <v-col cols="12" md="4" class="alarm-total-col">
                  <div class="total-sites-chart total-sites-alarm alarm-status-space">
                    <div class="total-sites-chart-column">
                      <div class="alarm-summary-legend">
                        <div class="sites-legend">
                          <span class="circle green-circle"></span>
                          <div class="sites-legend-desc">
                            <strong class="legend-desc-num">{{ dataForm?.count ? formatCommasAndDecimalForNumber(dataForm.count.resolved) : '' }}</strong>
                            <p class="legend-desc-status">Resolved <span class="status-percent">{{ dataForm?.status_percent ? formatDecimalNumber(dataForm.status_percent.resolved) : '' }}%</span></p>
                          </div>
                        </div>
                        <div class="sites-legend">
                          <span class="circle yellow-circle"></span>
                          <div class="sites-legend-desc">
                            <strong class="legend-desc-num">{{ dataForm?.count ? formatCommasAndDecimalForNumber(dataForm.count.wip) : '' }}</strong>
                            <p class="legend-desc-status">WIP <span class="status-percent">{{ dataForm?.status_percent ? formatDecimalNumber(dataForm.status_percent.wip) : '' }}%</span></p>
                          </div>
                        </div>
                        <div class="sites-legend">
                          <span class="circle grey-circle"></span>
                          <div class="sites-legend-desc">
                            <strong class="legend-desc-num">{{ dataForm?.count ? formatCommasAndDecimalForNumber(dataForm.count.new) : '' }}</strong>
                            <p class="legend-desc-status">New <span class="status-percent">{{ dataForm?.status_percent ? formatDecimalNumber(dataForm.status_percent.new) : '' }}%</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="alarm-search-col">
                  <div class="total-heading">
                    <h1>Total Alarm: {{ dataForm?.total ? formatCommasAndDecimalForNumber(dataForm.total) : 0 }}</h1>
                  </div>
                  <div class="switch-component switch-custom ps-0">
                    <v-text-field
                      v-model="search"
                      placeholder="Search..."
                      class="search-site"
                      prepend-inner-icon="fas fa-search"
                      rounded
                      outlined
                      filled
                    ></v-text-field>
                    <v-btn
                      class="switch-btn single-switch-btn button-hover"
                      icon
                      @click="openDialogFilterAlarm"
                      title="Filter Alarm"
                    >
                      <img src="@/assets/tables/filter-icon.svg" />
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.severity`]="{ item }">
              <span :style="`${item.severity === 'critical' ? 'color: #EB5C56' : ''}`">{{ capitalizeFirstLetter(item.severity) }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <td class="p-0 site-status-actions" :style="`background-color: ${ALARM_STATUS_COLORS[item.status]}`">
                <template v-if="!notAllowedActions() && ['new', 'wip'].includes(item.status)">
                  <v-select
                    :items="item.status === 'new' ? newStatusesList : wipStatusesList"
                    v-model="item.status"
                    item-text="name"
                    item-value="key"
                    required
                    filled
                    append-icon="fas fa-caret-down"
                    class="custom-table-select"
                    :menu-props="{ contentClass: 'custom_alarm_status' }"
                    @change="updateSiteStatus(item)"
                  ></v-select>
                </template>
                <template v-else>
                  <v-select
                    :items="siteStatusesList"
                    v-model="item.status"
                    item-text="name"
                    item-value="key"
                    required
                    filled
                    class="custom-table-select custom-status-view-only"
                    :menu-props="{ contentClass: 'custom_alarm_status' }"
                  ></v-select>
                </template>
              </td>
            </template>
          </v-data-table>
          <div class="data-table-paging flex-footer">
            <template v-if="dataForm?.records">
              <vue-json-to-csv
                v-if="!notAllowedActions()" 
                :json-data="dataForm.records"
                :labels="export_labels"
                :csv-title="'Alarm_management_csv'"
              >
                <v-btn
                  class="button-hover"
                  icon
                  title="Export"
                >
                  <img src="@/assets/tables/export-btn.svg" />
                </v-btn>
              </vue-json-to-csv>
              <v-btn v-else icon style="visibility: hidden;">
                <img src="@/assets/tables/export-btn.svg" />
              </v-btn>
            </template>
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="5"
              :dark="true"
            ></v-pagination>
          </div>
        </v-col>
        <v-col cols="12" md="0" sm="0" />
      </v-row>
      <v-dialog
        v-model="dialogFilterAlarm"
        persistent
        content-class="custom-content-modals"
        max-width="700"
      >
        <v-card>
          <v-card-title class="v-card__title-filter">Filter</v-card-title>
          <v-card-text class="custom-asset-card v-card__text-filter">
            <v-form
              ref="filterAlarmForm"
              @submit.prevent="submitFilter"
              autocomplete="off"
            >
              <v-row no-gutters>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Device</p>
                  <v-row no-gutters class="checkboxes-row">
                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceTypes" class="cursor-pointer" type="checkbox" id="type-sc" name="type-sc" value="sc" />
                        <label for="type-sc">eBox</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceTypes" class="cursor-pointer" type="checkbox" id="type-pm" name="type-pm" value="pm" />
                        <label for="type-pm">Smart Meter</label>
                      </div>
                    </v-col>

                    <v-col cols="4" style="visibility: hidden;">
                      <div class="option-item">
                        <input class="cursor-pointer" type="checkbox" id="type-hidden" />
                        <label for="type-hidden">Hidden</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceTypes" class="cursor-pointer" type="checkbox" id="type-pv" name="type-pv" value="pv" />
                        <label for="type-pv">PV Inverter</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceTypes" class="cursor-pointer" type="checkbox" id="type-bess" name="type-bess" value="bess" />
                        <label for="type-bess">BESS</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedDeviceTypes" class="cursor-pointer" type="checkbox" id="type-evse" name="type-evse" value="evse" />
                        <label for="type-evse">EVSE</label>
                      </div>
                    </v-col>          
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Severity</p>
                  <v-row no-gutters class="checkboxes-row">
                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedSeverities" class="cursor-pointer" type="checkbox" id="critical" name="critical" value="critical" />
                        <label for="critical">Critical</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedSeverities" class="cursor-pointer" type="checkbox" id="major" name="major" value="major" />
                        <label for="major">Major</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedSeverities" class="cursor-pointer" type="checkbox" id="minor" name="minor" value="minor" />
                        <label for="minor">Minor</label>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Alarm Status</p>
                  <v-row no-gutters class="checkboxes-row">
                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedAlarmStatuses" class="cursor-pointer" type="checkbox" id="new-status" name="new-status" value="new" />
                        <label for="new-status">New</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedAlarmStatuses" class="cursor-pointer" type="checkbox" id="wip-status" name="wip-status" value="wip" />
                        <label for="wip-status">WIP</label>
                      </div>
                    </v-col>

                    <v-col cols="4">
                      <div class="option-item">
                        <input v-model="selectedAlarmStatuses" class="cursor-pointer" type="checkbox" id="resolved-status" name="resolved-status" value="resolved" />
                        <label for="resolved-status">Resolved</label>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" class="text-light">
                  <p class="filter-name">Start Date</p>
                  <v-row no-gutters class="checkboxes-row commissioned-date-row">
                    <v-col cols="1" md="1" class="date-picker-title">
                      <p>From</p>
                    </v-col>
                    <v-col cols="3" md="3" class="date-picker-table">
                      <v-menu
                        ref="commissionedDateMenu"
                        v-model="commissionedDateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="332"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="commissionedDateTextFrom"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            append-icon="fas fa-caret-down"
                            class="date-picker-text-field"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          flat
                          v-model="commissionedDateRange"
                          no-title
                          range
                          width="auto"
                          first-day-of-week="0"
                          type="date"
                          :min="rangeMinDate"
                          :max="rangeMaxDate"
                          :dark="true"
                          class="customize_commissioned_date_picker"
                          @input="closeCommissionedDateMenu"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="1" md="1" class="date-picker-title">
                      <p style="margin-left: 13px;">To</p>
                    </v-col>
                    <v-col cols="3" md="3" class="date-picker-table">
                      <v-text-field
                        v-model="commissionedDateTextTo"
                        readonly
                        append-icon="fas fa-caret-down"
                        class="date-picker-text-field"
                        @click="openCommissionedDateMenu"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters class="filter-buttons-row alarm-buttons-row">
                <v-spacer></v-spacer>
                <v-col cols="12" class="text-center">
                  <button
                    class="dialog-default-button dialog-button-filter-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="closeDialogFilterAlarm"
                  >
                    CANCEL
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-filter-size dialog-bg-outline-button"
                    :ripple="false"
                    type="button"
                    @click="resetDialogFilterAlarm"
                  >
                    RESET
                  </button>
                  <button
                    class="ml-4 dialog-default-button dialog-button-filter-size dialog-bg-grey-button"
                    :ripple="false"
                    type="submit"
                  >
                    APPLY
                  </button>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="672"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title>{{ dialogHeading }}</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="dialog-content">
                <span>{{ dialogMessage }}</span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-outline-button"
                  type="button"
                  :ripple="false"
                  @click="closeConfirmDialog"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="dialogAction(alarmItem)"
                >
                  CONFIRM
                </button>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="messageDialog"
        max-width="500"
        content-class="confirm-dialog-box"
      >
        <v-card>
          <v-card-title class="justify-content-center">{{ apiTitle }}</v-card-title>
          <v-card-text
            ><v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span v-html="apiMessage"></span>
              </v-col>
              <v-col cols="12" class="dialog-buttons-padding text-center">
                <button
                  class="dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                  type="button"
                  :ripple="false"
                  @click="closeMessage"
                >
                  OK
                </button>
              </v-col>
            </v-row></v-card-text
          >
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<style lang="scss" src="@/assets/css/general-datatable-page.scss"></style>
<script>
import { alarmService, } from "@/services";
import moment from "moment";
import VueJsonToCsv from 'vue-json-to-csv'
export default {
  components: { VueJsonToCsv },
  data() {
    return {
      search: "",
      headers: [
        { text: "Alarm ID", value: "id", align: 'center', width: 110 },
        { text: "Error Code", value: "code", align: 'center' },
        { text: "Device", value: "asset_name", align: 'center' },
        { text: "Description", value: "description", align: 'center', sortable: false },
        { text: "Severity", value: "severity", align: 'center' },
        { text: "Start Date & Time", value: "start_time", align: 'center', sortable: false },
        { text: "Resolved Date & Time", value: "resolve_time", align: 'center', sortable: false },
        { text: "Alarm Status", value: "status", align: 'center', sortable: false, width: 122 },
      ],
      dataForm: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialogFilterAlarm: false,
      selectedDeviceTypes: [],
      selectedSeverities: [],
      selectedAlarmStatuses: [],
      commissionedDateRange: [],
      commissionedDateMenu: false,
      newStatusesList: [
        {
          key: "new",
          name: "New",
        },
        {
          key: "wip",
          name: "WIP",
        },
      ],
      wipStatusesList: [
        {
          key: "wip",
          name: "WIP",
        },
        {
          key: "resolved",
          name: "Resolved",
        }
      ],
      siteStatusesList: [
        {
          key: "new",
          name: "New",
        },
        {
          key: "wip",
          name: "WIP",
        },
        {
          key: "resolved",
          name: "Resolved",
        }
      ],
      confirmDialog: false,
      dialogHeading: "",
      dialogMessage: "",
      dialogAction: () => null,
      alarmItem: {},
      messageDialog: false,
      apiTitle: "",
      apiMessage: "",
      export_labels: {
        id: { title: 'Alarm ID' }, 
        code: { title: 'Error Code' }, 
        asset_name: { title: 'Device' }, 
        description: { title: 'Description' }, 
        severity: { title: 'Severity' }, 
        start_time: { title: 'Start Date & Time' }, 
        resolve_time: { title: 'Resolved Date & Time' }, 
        status: { title: 'Alarm Status' },
      }
    };
  },
  created() {
    this.getListAlarms();
  },
  computed: {
    user: function () {
      return this.$store.getters.user;
    },
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    },
    commissionedDateTextFrom () {
      if(this.commissionedDateRange && this.commissionedDateRange.length > 0){
        return `${this.commissionedDateRange[0] ? moment(this.commissionedDateRange[0]).format("DD/MM/YYYY") : ''}`;
      }
      return null;
    },
    commissionedDateTextTo () {
      if(this.commissionedDateRange && this.commissionedDateRange.length > 1){
        return `${this.commissionedDateRange[1] ? moment(this.commissionedDateRange[1]).format("DD/MM/YYYY") : ''}`;
      }
      return null;
    },
    rangeMinDate () {
      const minDate = this.commissionedDateRange?.length === 1 ? this.commissionedDateRange[0] : null;
      return minDate;
    },
    rangeMaxDate () {
      const maxDate = this.NOW_DATE().format(this.DATE_PICKER_FORMAT);
      return maxDate;
    },
  },
  methods: {
    getListAlarms(filters) {
      let data = {
        site_id: this.$store.state.siteId ? this.$store.state.siteId : null,
      };
      const params = { ...data, ...filters };
      alarmService.getListAlarms(params).then((res) => {
        if (res?.data?.data) {
          const data = res.data.data;
          if (data?.records?.length) {
            data.records.forEach(alarm => {
              alarm.resolve_time = this.formatDataTableDate(alarm?.resolve_time) || "−−";
              alarm.start_time = this.formatDataTableDate(alarm?.start_time) || "−−";
            });
          }
          this.dataForm = {...data};
        }
      });
    },
    openDialogFilterAlarm() {
      this.dialogFilterAlarm = true;
    },
    resetDialogFilterAlarm() {
      this.selectedDeviceTypes = [];
      this.selectedSeverities = [];
      this.selectedAlarmStatuses = [];
      this.commissionedDateRange = [];
    },
    closeDialogFilterAlarm() {
      this.dialogFilterAlarm = false;
    },
    openCommissionedDateMenu() {
      return this.commissionedDateMenu = !this.commissionedDateMenu;
    },
    closeCommissionedDateMenu() {
      if(this.commissionedDateRange && this.commissionedDateRange[0] && this.commissionedDateRange[1]){
        this.commissionedDateMenu = false;
      }else{
        this.commissionedDateMenu = true;
      }
      return this.commissionedDateMenu;
    },
    async submitFilter() {
      if(!(this.selectedDeviceTypes?.length || this.selectedSeverities?.length || this.selectedAlarmStatuses?.length || this.commissionedDateRange?.length)) {
        await this.getListAlarms();
      } else {
        let filters = {};
        if(this.selectedDeviceTypes?.length) {
          filters.device_type = JSON.stringify(this.selectedDeviceTypes);
        }
        if(this.selectedSeverities?.length) {
          filters.severity = JSON.stringify(this.selectedSeverities);
        }
        if(this.selectedAlarmStatuses?.length) {
          filters.status = JSON.stringify(this.selectedAlarmStatuses);
        }
        if(this.commissionedDateRange?.length === 2) {
          filters.start_date = this.commissionedDateRange[0];
          filters.end_date = this.commissionedDateRange[1];
        }
        await this.getListAlarms(filters);
      }
      this.closeDialogFilterAlarm();
    },
    async updateSiteStatus(item) {
      if(item?.id) {
        let data = {
          status: item.status
        }
        await alarmService
          .updateAlarmStatus(item.id, data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.closeConfirmDialog();
            this.apiMessage = `Alarm status has been successfully updated to ${item.status}`;
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "Alarm status cannot be updated";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    openConfirmDialog(item, action, heading, message) {
      this.alarmItem = Object.assign({}, this.alarmItem, item);
      this.dialogAction = action;
      this.dialogHeading = heading;
      this.dialogMessage = message;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.alarmItem = Object.assign({}, this.alarmItem);
      this.confirmDialog = false;
      this.dialogAction = () => null;
      this.dialogHeading = "";
      this.dialogMessage = "";
      this.getListAlarms();
    },
    closeMessage() {
      this.messageDialog = false;
    },
    notAllowedActions () {
      return (!this.accessibleRoutes?.alarm?.action || ['home_user'].includes(this.user?.user_group?.type));
    }
  },
};
</script>
